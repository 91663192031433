import React, { useState, useEffect, useRef } from 'react';
import { TouchableOpacity, View, StyleSheet, Platform, Animated, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMicrophone } from '@fortawesome/pro-solid-svg-icons';
import AudioRecorderPlayer, {
  AudioEncoderAndroidType,
  AudioSourceAndroidType,
  AVEncoderAudioQualityIOSType,
  AVEncodingOption
} from 'react-native-audio-recorder-player';

interface Ripple {
  key: number;
  animation: Animated.Value;
  volume: number;
}

const RippleEffect = ({ ripple }: { ripple: Ripple }) => {
  useEffect(() => {
    Animated.timing(ripple.animation, {
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, []);

  const rippleScale = ripple.animation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 2 + ripple.volume],
  });

  const rippleOpacity = ripple.animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0.4, 0],
  });

  return (
    <Animated.View
      style={[
        styles.ripple,
        {
          transform: [{ scale: rippleScale }],
          opacity: rippleOpacity,
        },
      ]}
    />
  );
};

export const AudioRecorderComponent: React.FC<{
  handleAudioSend: (formData: FormData) => void;
}> = ({ handleAudioSend }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPreparing, setIsPreparing] = useState(false);
  const [volume, setVolume] = useState(0);
  const [ripples, setRipples] = useState<Ripple[]>([]);
  const [recordingTime, setRecordingTime] = useState(0);
  const audioRecorderPlayerRef = useRef<AudioRecorderPlayer | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const rippleKeyRef = useRef(0);
  const lastRippleTimeRef = useRef(0);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const audioStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      audioRecorderPlayerRef.current = new AudioRecorderPlayer();
    }
    return () => {
      if (isRecording) {
        onStopRecord();
      }
      // Additional cleanup for web
      if (Platform.OS === 'web' && audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach(track => track.stop());
        audioStreamRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isRecording && volume > 0.1) {
      const now = Date.now();
      if (now - lastRippleTimeRef.current > 200) {
        const newRipple: Ripple = {
          key: rippleKeyRef.current++,
          animation: new Animated.Value(0),
          volume: volume,
        };
        setRipples(prevRipples => [...prevRipples, newRipple]);
        lastRippleTimeRef.current = now;

        setTimeout(() => {
          setRipples(prevRipples => prevRipples.filter(r => r.key !== newRipple.key));
        }, 1000);
      }
    }
  }, [volume, isRecording]);

  useEffect(() => {
    if (isRecording) {
      timerIntervalRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } else {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
      setRecordingTime(0);
    }
    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [isRecording]);

  const updateVolume = (analyser: AnalyserNode) => {
    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);
    const average = dataArray.reduce((a, b) => a + b) / dataArray.length;
    setVolume(Math.min(average / 128, 1));
    requestAnimationFrame(() => updateVolume(analyser));
  };

  const onStartRecord = async () => {
    setIsPreparing(true);
    if (Platform.OS === 'web') {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioStreamRef.current = stream;
        const mediaRecorder = new window.MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];

        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        mediaRecorder.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorder.onstart = () => {
          setIsPreparing(false);
          setIsRecording(true);
        };

        mediaRecorder.start();
        updateVolume(analyser);
      } catch (err) {
        console.error('Error starting recording:', err);
        setIsPreparing(false);
        alert('Unable to access the microphone. Please check your browser settings and try again.');
      }
    } else {
      try {
        const audioSet = {
          AudioEncoderAndroid: AudioEncoderAndroidType.AAC,
          AudioSourceAndroid: AudioSourceAndroidType.MIC,
          AVEncoderAudioQualityKeyIOS: AVEncoderAudioQualityIOSType.high,
          AVNumberOfChannelsKeyIOS: 2,
          AVFormatIDKeyIOS: AVEncodingOption.aac,
        };
        const uri = await audioRecorderPlayerRef.current?.startRecorder(undefined, audioSet);
        audioRecorderPlayerRef.current?.addRecordBackListener((e) => {
          setVolume(Math.min((e.currentMetering ?? 0) / 120 + 1, 1));
        });
        setIsPreparing(false);
        setIsRecording(true);
        console.log(`URI: ${uri}`);
      } catch (err) {
        console.error('Error starting recording on React Native:', err);
        setIsPreparing(false);
        alert('Unable to access the microphone. Please check your app permissions and try again.');
      }
    }
  };

  const onStopRecord = async () => {
    try {
      if (Platform.OS === 'web') {
        if (mediaRecorderRef.current) {
          mediaRecorderRef.current.stop();
          mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            const formData = new FormData();
            formData.append('file', audioBlob, 'audio.webm');
            handleAudioSend(formData);
          };
        }
      } else {
        try {
          const result = await audioRecorderPlayerRef.current?.stopRecorder();
          audioRecorderPlayerRef.current?.removeRecordBackListener();
          const formData = new FormData();
          formData.append('file', {
            uri: result,
            type: 'audio/m4a',
            name: 'audio.m4a',
          } as any);
          handleAudioSend(formData);
        } catch (err) {
          console.error('Error stopping recording on React Native:', err);
        }
      }
    } catch (err) {
      console.error('Error stopping recording:', err);
    } finally {
      // Ensure these actions are always performed, even if an error occurs
      if (Platform.OS === 'web' && audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach(track => track.stop());
        audioStreamRef.current = null;
      }
      setIsRecording(false);
      setVolume(0);
      setRipples([]);
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      onStopRecord();
    } else {
      onStartRecord();
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <View style={styles.container}>
      {ripples.map(ripple => (
        <RippleEffect key={ripple.key} ripple={ripple} />
      ))}
      <TouchableOpacity
        style={[styles.button, isPreparing && styles.preparingButton]}
        onPress={toggleRecording}
        activeOpacity={0.7}
        disabled={isPreparing}
      >
        <FontAwesomeIcon
          icon={faMicrophone}
          color={isRecording ? '#FF3B30' : (isPreparing ? '#FFA500' : 'inherit')}
          size={16}
        />
      </TouchableOpacity>
      {(isRecording || isPreparing) && (
        <View style={styles.timerContainer}>
          <Text style={styles.timerText}>
            {isRecording && formatTime(recordingTime)}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: 42,
    height: 42,
    borderRadius: 10,
    backgroundColor: '#ebebeb',
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    zIndex: 1,
  },
  preparingButton: {
    backgroundColor: '#FFF0D4', // Un colore leggermente arancione per indicare lo stato di preparazione
  },
  ripple: {
    position: 'absolute',
    width: 42,
    height: 42,
    borderRadius: 10,
    backgroundColor: '#FF3B30',
  },
  timerContainer: {
    position: 'absolute',
    bottom: -18,
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 15,
  },
  timerText: {
    marginTop: 8,
    fontSize: 14,
    color: '#333',
  },
});
