import type { DebugInfo } from '../interfaces/DebugInfo.interface';
import type { HistoryInterface, MessagePart } from '../interfaces/History.interface';
import type { MessageInterface } from '../interfaces/Message.interface';

export function textToImessage({
  text,
  timestamp,
  id,
  role,
  debug,
}: {
  text: string;
  timestamp: string;
  id: string;
  name: string;
  role: 'user' | 'assistant';
  debug?: DebugInfo;
}): MessageInterface {
  return {
    id,
    parts: [{
      type: 'text', content: {
        Content: {
          Text: text
        }
      }
    } as MessagePart],
    isUser: role === 'user',
    createdAt: new Date(timestamp),
    debug,
    scrollToEndFix: false,
    complete: true,
  };
}

export const conversationHistoryToChatMessages = (
  history: HistoryInterface[]
): MessageInterface[] => {
  return history.map((item, index) => ({
    id: `${index}`,
    parts: item.message_parts,
    //text: (item.message_parts as MessagePart[])?.filter(mp => mp.type === 'text').map(mp => mp.content.Content.Text).join(''),
    isUser: item.role === 'user',
    createdAt: new Date(item.timestamp),
    debug: item.debug,
    complete: true,
    scrollToEndFix: false,
  }));
};

// {  role: 'user' | 'assistant';
// content: string;
// timestamp: string; // '2024-02-14T09:00:45Z';
// audio_file_url: string;
// debug: {
//   context: string;
//   queries: {
//     changed: boolean;
//     used_query: string;
//     original_query: string;
//   };
//   timings: {
//     oai_query_millis_d2: number;
//     query_preprocess_millis: number;
//     vect_db_query_millis_d2: number;
//     token_limit_check_millis_d2: number;
//   };
//   sections?: DebugInfo;
// };
