import { useCallback, useEffect, useState } from 'react';
import type { LanguageType } from '../ConversationAi';

interface UseLanguageApiProps {
  preferencesUrl: string;
  headers: Headers;
  anonymous: boolean;
}

export const useLanguageApi = ({
  preferencesUrl,
  headers,
  anonymous
}: UseLanguageApiProps) => {
  const [isLanguageUpdating, setIsLanguageUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [language, setLanguage] = useState<LanguageType | undefined>(undefined);


  const updateLanguage = useCallback(async (newLanguage: LanguageType) => {
    setIsLanguageUpdating(true);
    setError(null);
    try {
      const response = await fetch(preferencesUrl, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ language: newLanguage }),
      });
      if (!response.ok) {
        throw new Error('Failed to update language on server');
      }
      setLanguage(newLanguage);
      return true;
    } catch (error) {
      console.error('Error updating language:', error);
      setError('Unable to change the language. Please try again later.');
      return false;
    } finally {
      setIsLanguageUpdating(false);
    }
  }, [preferencesUrl, headers]);

  const getLanguage = useCallback(async () => {
    try {
      const response = await fetch(preferencesUrl, { headers });
      if (!response.ok) {
        throw new Error('Failed to get language from server');
      }
      const data = await response.json();
      setLanguage(data.language);
    } catch (error) {
      console.error('Error getting language:', error);
      setError('Unable to get the language. Please try again later.');
    }
  }, [preferencesUrl, headers]);

  useEffect(() => {
    if ((anonymous === false && headers.get('Authorization')) || anonymous === true) {
      getLanguage();
    }
  }, [headers, getLanguage, anonymous]);

  return { updateLanguage, isLanguageUpdating, error, language };
};
