import React, { useState } from 'react';
import { Text, View } from 'tamagui';
import { StyleSheet, Image, TouchableOpacity, Modal } from 'react-native';
import { format } from 'date-fns';
import type { MessageInterface } from '../interfaces/Message.interface';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCircleQuestion, faTimes } from '@fortawesome/pro-solid-svg-icons';
import chatgptImage from '../assets/chatgpt.jpg';

const styles = StyleSheet.create({
  message: {
    borderRadius: 15,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  userMessage: {
    borderTopRightRadius: 3,
  },
  assistantMessage: {
    borderBottomLeftRadius: 3,
  },
  timestamp: {
    fontSize: 10,
    marginBottom: 5,
  },
  day: {
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
  },
  inlineImage: {
    width: '100%',
    height: 150,
    marginVertical: 10,
  },
  fullscreenImage: {
    flex: 1,
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  modalCloseButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: 'white',
    borderStyle: 'solid',
    padding: 5
  },
  touchableCloseButton: {
    position: 'absolute',
    top: 40,
    right: 20,
    zIndex: 1,
  },
});

export const Message = ({
  message: {
    parts,
    isUser,
    createdAt,
    showDaylabel,
    audioUrl,
    debug,
    scrollToEndFix,
  },
  messageMaxWidth,
  primaryColor,
  assistantColor,
  primaryTextColor,
  assistantTextColor,
  dayLabelColor,
  messagesAdditionalStyles,
  botIcon = chatgptImage,
  useBotIcon,
  debugClicked,
  showDebug,
  botIconSize,
}: {
  message: MessageInterface;
  messageMaxWidth?: number;
  primaryColor?: string;
  assistantColor?: string;
  primaryTextColor?: string;
  assistantTextColor?: string;
  dayLabelColor: string;
  messagesAdditionalStyles?: object;
  botIcon?: React.ReactNode;
  useBotIcon?: boolean;
  debugClicked: () => void;
  showDebug: boolean;
  botIconSize: number;
}) => {
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);
  const color = isUser ? primaryTextColor : assistantTextColor;
  const backgroundColor = isUser ? primaryColor : assistantColor;

  const renderTextWithImages = () => {
    return parts.map((part, index) => {
      if (part.type === 'text') {
        return (
          <Text key={index} style={messagesAdditionalStyles} color={color}>
            {part.content.Content.Text}
          </Text>
        );
      }
      if (part.type === 'metadata' && part.content.Content.Resources.metadata_chunks) {
        return part.content.Content.Resources.metadata_chunks.map(
          (attachment, attachmentIndex) => (
            <React.Fragment key={attachmentIndex}>
              <TouchableOpacity onPress={() => setFullscreenImage(attachment.s3_key)}>
                <Image
                  source={{ uri: attachment.s3_key }}
                  style={styles.inlineImage}
                  resizeMode="contain"
                />
              </TouchableOpacity>
              <Text style={[styles.timestamp, { textAlign: 'center' }]}>
                {attachment.label}
              </Text>
            </React.Fragment>
          ));
      }
      return null;
    });
  };

  if (scrollToEndFix) {
    return <View></View>;
  }
  return (
    <>
      {showDaylabel && (
        <Text style={styles.day} color={dayLabelColor}>
          {format(createdAt, 'MMM d, yyyy')}
        </Text>
      )}
      <View
        style={{
          flex: 1,
          margin: 10,
        }}
      >
        <View
          style={{
            marginLeft: isUser ? 'auto' : 'inherit',
            marginRight: !isUser ? 'auto' : 'inherit',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          {!isUser && useBotIcon && botIcon && (
            <Image
              source={
                typeof botIcon === 'number'
                  ? botIcon
                  : { uri: botIcon as string }
              }
              style={{ width: botIconSize, height: botIconSize }}
            ></Image>
          )}

          <View
            style={{
              ...styles.message,
              ...(isUser ? styles.userMessage : styles.assistantMessage),
              maxWidth: messageMaxWidth,
              color,
              backgroundColor,
            }}
          >

            {/* <View>
              {text.split('\n').map((line, index) => (
                <Text
                  color={color}
                  style={messagesAdditionalStyles}
                  key={index}
                >
                  {line === '' ? 'aaa' : line}
                </Text>
              ))}
            </View> */}

            {renderTextWithImages()}

            <Text
              color={color}
              textAlign={isUser ? 'right' : 'left'}
              style={styles.timestamp}
            >
              {format(createdAt, 'hh:mm a')}
            </Text>
            {audioUrl && (
              <>
                <audio controls autoPlay>
                  <source src={audioUrl} type="audio/mpeg" />
                </audio>
              </>
            )}


            {showDebug && !isUser && debug && debug.sections && (
              <View style={{ alignSelf: 'end' }}>
                <TouchableOpacity
                  onPress={() => {
                    showDebug ? debugClicked() : null;
                  }}
                >
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
      <Modal visible={!!fullscreenImage} transparent={true} animationType="fade">
        <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
          <TouchableOpacity
            style={styles.touchableCloseButton}
            onPress={() => setFullscreenImage(null)}
          >
            <View style={styles.modalCloseButton}>
              <FontAwesomeIcon icon={faTimes} size={30} color="white" />
            </View>
          </TouchableOpacity>
          {fullscreenImage && (
            <Image
              source={{ uri: fullscreenImage }}
              style={styles.fullscreenImage}
            />
          )}
        </View>
      </Modal>
    </>
  );
};
