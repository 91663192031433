import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { InteractionManager } from 'react-native';
import { Message } from './Message';
import { type MessageInterface } from '../interfaces/Message.interface';
import { FlashList } from '@shopify/flash-list';
import { startOfDay, isSameDay } from 'date-fns';
import { View } from 'tamagui';

interface MessageWithDay extends MessageInterface {
  dayLabel?: boolean;
  showDaylabel?: boolean;
}
// Helper function to group messages by day
const groupMessagesByDay = (messages: MessageWithDay[], inverted = false) => {
  messages.forEach((message, index) => {
    const messageDay = startOfDay(message.createdAt);

    if (
      !inverted &&
      (index === 0 ||
        !isSameDay(
          messageDay,
          startOfDay(messages[index + 1]?.createdAt || new Date())
        ))
    ) {
      message.showDaylabel = true; // Add dayLabel field to the existing message
    }

    if (
      inverted &&
      (index === messages.length - 1 ||
        !isSameDay(
          messageDay,
          startOfDay(messages[index + 1]?.createdAt || new Date())
        ))
    ) {
      message.showDaylabel = true; // Add dayLabel field to the existing message
    }
  });
  return messages; // Return the updated messages array
};

export interface MessagesList {
  scrollToIndex: (index: number) => void;
  scrollToEnd: (options?: { animated: boolean }) => void;
  setAutoScroll: (autoScroll: boolean) => void;
}

export interface MessagesListProps {
  messages: MessageInterface[];
  inverted?: boolean;
  dayLabelColor: string;
  primaryColor: string;
  assistantColor: string;
  primaryTextColor: string;
  assistantTextColor: string;
  messagesAdditionalStyles?: object;
  debugClicked: (message: MessageInterface) => void;
  showDebug: boolean;
  botIcon?: React.ReactNode;
  useBotIcon?: boolean;
  messageMaxWidth?: number;
  attachments?: {
    url: string;
    type: string;
    label: string;
  }[];
  botIconSize?: number;
}

export const MessagesList = forwardRef(
  (
    {
      messages,
      inverted,
      dayLabelColor,
      primaryColor,
      assistantColor,
      primaryTextColor,
      assistantTextColor,
      messagesAdditionalStyles,
      showDebug = false,
      debugClicked,
      useBotIcon,
      botIcon,
      messageMaxWidth,
      botIconSize = 30,
    }: MessagesListProps,
    ref
  ) => {
    const flashListRef = useRef<FlashList<MessageInterface>>(null);
    const groupedMessages = groupMessagesByDay(messages, inverted);
    const [autoScroll, setAutoScroll] = useState(false);
    const [autoScrollOverriddenByUser, setAutoScrollOverriddenByUser] =
      useState(false);
    const [lastContentOffset, setLastContentOffset] = useState(0);
    useImperativeHandle(ref, () => ({
      scrollToIndex: (index: number) => {
        InteractionManager.runAfterInteractions(() => {
          if (
            flashListRef.current &&
            index >= 0 &&
            index < groupedMessages.length
          ) {
            flashListRef?.current?.scrollToIndex({
              index: index,
              animated: true,
            });
          }
        });
      },
      scrollToEnd: (options = { animated: true }) => {
        // If user has scrolled up when auto-scrolling, we should not auto-scroll to bottom
        if (!autoScrollOverriddenByUser) {
          flashListRef?.current?.scrollToEnd({
            animated: options.animated,
          });
        }
      },
      setAutoScroll: (autoScroll: boolean) => {
        setAutoScroll(autoScroll);
      },
    }));

    const onScroll = useCallback(
      (event: any) => {
        const { contentOffset } = event.nativeEvent;

        if (autoScroll && !autoScrollOverriddenByUser) {
          if (contentOffset.y < lastContentOffset) {
            console.log('Disabling autoscroll');
            // console.log(
            //   'contentOffset.y < lastContentOffset',
            //   contentOffset.y,
            //   lastContentOffset
            // );
            setAutoScrollOverriddenByUser(true);
          }
        }
        if (autoScroll && autoScrollOverriddenByUser) {
          if (lastContentOffset < contentOffset.y) {
            const { contentSize, layoutMeasurement } = event.nativeEvent;
            const distanceFromBottom =
              contentSize.height - (contentOffset.y + layoutMeasurement.height);
            const nearBottom = distanceFromBottom < 100;
            if (nearBottom) {
              console.log('Enabling autoscroll');
              console.log(
                'lastContentOffset >= contentOffset.y',
                lastContentOffset,
                contentOffset.y
              );
              setAutoScrollOverriddenByUser(false);
            }
          }
        }
        setLastContentOffset(contentOffset.y);
      },
      [autoScroll, lastContentOffset, autoScrollOverriddenByUser]
    );

    const renderItem = ({ item }: { item: MessageInterface }) => {
      return (
        <View>
          <Message
            messageMaxWidth={messageMaxWidth}
            message={item}
            primaryColor={primaryColor}
            assistantColor={assistantColor}
            primaryTextColor={primaryTextColor}
            assistantTextColor={assistantTextColor}
            dayLabelColor={dayLabelColor}
            messagesAdditionalStyles={messagesAdditionalStyles}
            botIcon={botIcon}
            useBotIcon={useBotIcon}
            showDebug={showDebug}
            debugClicked={() => debugClicked(item)}
            botIconSize={botIconSize}
          />
        </View>
      );
    };
    return (
      <FlashList
        ref={flashListRef}
        renderItem={renderItem}
        data={groupedMessages}
        inverted={inverted}
        //onContentSizeChange={() => refs.flatList.scrollToEnd()}
        //estimatedFirstItemOffset={50}
        keyExtractor={(item, _index) => `${item.isUser}-${item.id}}`}
        drawDistance={100000} // Increase distance to pre-render items
        onScroll={onScroll}
      />
    );
  }
);
