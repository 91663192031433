import { useState } from 'react';

interface UseAudioApiProps {
  audioUrl: string;
  headers: Headers;
}

export const useAudioApi = ({ audioUrl, headers }: UseAudioApiProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendAudio = async (formData: FormData) => {
    setIsLoading(true);
    setError(null);

    try {
      // Remove the Content-Type header
      headers.delete('Content-Type');

      const response = await fetch(audioUrl, {
        method: 'POST',
        body: formData,
        headers,
      });

      if (!response.ok) {
        throw new Error('Failed to send audio');
      }

      return true;
    } catch (error) {
      console.error('Error sending audio:', error);
      setError('Unable to send audio. Please try again later.');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { sendAudio, isLoading, error };
};
