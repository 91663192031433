import { useState, useEffect, useCallback } from 'react';
import { conversationHistoryToChatMessages } from '../services/MessageFormatter';
import { conversationHistory } from '../services/ConversationHistory';
import type { MessageInterface } from '../interfaces/Message.interface';
import type { MessagesList } from '../components/MessageList';
import type { ConversationAiSocketMsg } from '../interfaces/ConversationAiSocket.interface';
import type { MessagePart, MessagePartMetadata } from '../interfaces/History.interface';

interface UseConversationParams {
  apiBaseUrl: string;
  authToken?: string;
  organizationId?: string;
  anonymous: boolean;
  welcomeMessages?: { language: string; label: string; }[];
  onError?: (error: string) => void;
  requesterId?: string;
  language?: string;
}

export const useConversation = ({
  apiBaseUrl,
  authToken,
  organizationId,
  anonymous,
  welcomeMessages,
  onError,
  requesterId,
  language
}: UseConversationParams) => {
  const [messages, setMessages] = useState<MessageInterface[]>([]);
  const [recevingNewMessage, setRecevingNewMessage] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false);

  useEffect(() => {
    if (welcomeMessages && language) {
      let wecomeMessage = welcomeMessages.find(wm => wm.language === language)
      // default to english if present
      if (!wecomeMessage) {
        wecomeMessage = welcomeMessages.find(wm => wm.language === 'en')
      }
      if (wecomeMessage && messages.length === 0) {
        setMessages([
          {
            id: 'welcomemessage',
            parts: [{
              type: 'text', content: {
                Content: {
                  Text: wecomeMessage.label,
                }
              }
            }],
            isUser: false,
            createdAt: new Date(),
            showDaylabel: false,
            scrollToEndFix: false,
            complete: true
          },
        ]);
      }

    }

    if (anonymous) {
      setMessagesLoaded(true);
    } else {
      if (organizationId && authToken && !messagesLoaded) {
        fetchConversationHistory(authToken);
      }
    }
  }, [organizationId, authToken, anonymous, language]);

  const fetchConversationHistory = async (authToken: string) => {
    try {
      const url = new URL(`${apiBaseUrl}/org/${organizationId}/history2`);
      if (requesterId) {
        url.searchParams.append('requester_id', requesterId);
      }
      const conversationHistoryRes = await conversationHistory(
        url.href,
        authToken
      ) || [];

      const msgs = conversationHistoryToChatMessages(conversationHistoryRes);
      setMessages(msgs);
      setMessagesLoaded(true);
    } catch (e: unknown) {
      console.error(e);
      if (onError) {
        onError((e as Error).toString());
      }
    }
  };

  const handleNewMessage = useCallback(
    (message: ConversationAiSocketMsg, messagesList: MessagesList) => {
      if (message.complete) {
        //messagesList.scrollToEnd({ animated: false });
        setRecevingNewMessage(false);
        messagesList.setAutoScroll(false);
      } else {
        messagesList.setAutoScroll(true);
      }
      if (message.complete) {
        setRecevingNewMessage(false);
      }

      setMessages(previousMessages => {
        let newPart: MessagePart | MessagePartMetadata;
        if (message.type === 'images') {
          newPart = {
            type: 'metadata',
            content: {
              Content: {
                Resources: {
                  metadata_chunks: (message.images || []).map((image) => ({ label: image.label, res_id: '', s3_key: image.url }))
                }
              }
            }
          }
        } else {
          newPart = {
            type: 'text',
            content: {
              Content: {
                Text: message.content || "",
              }
            }
          }
        }
        let parts: (MessagePart | MessagePartMetadata)[] = [newPart];
        let newMessagesIndex: number;
        // if new message is from user it means it's the sent audio transcript and it's a complete message
        if (message.isUser) {
          newMessagesIndex = previousMessages.length;
        } else {
          // message is from assistant
          // If last message is from user it means this is the first part of a new message
          if (previousMessages[previousMessages.length - 1]?.complete) {
            newMessagesIndex = previousMessages.length;
          } else {
            // last message is from assistant, it means this message is partial and not yet complete
            newMessagesIndex = previousMessages.length - 1;
            const lastMessage = previousMessages[newMessagesIndex];

            // this is needed cause if we do not add a new message after the last message, when auto scrolling to bottom, scrolling will stop as soon as the top of message reaches the top of the screen
            if (lastMessage?.scrollToEndFix) {
              newMessagesIndex = previousMessages.length - 2;
            }
            // TODO: if current part is text and next part is metadata, we should create a new part and vice versa
            const myParts = previousMessages[newMessagesIndex]?.parts || []
            const lastPart = myParts[myParts.length - 1];
            if (lastPart?.type === 'text' && newPart.type === 'text') {
              lastPart.content.Content.Text += newPart.content.Content.Text;
              parts = [...myParts];
            } else {
              // if last part is metadata and new part is text (or vice versa), we should create a new part
              parts = [...myParts, newPart];
            }
          }
        }

        const newMessages = [
          ...previousMessages.filter((_m, i) => i < newMessagesIndex),
          {
            id: `${newMessagesIndex}`,
            parts,
            createdAt: new Date(message.createdAt),
            isUser: message.isUser,
            audioUrl: message.audioUrl,
            scrollToEndFix: false,
            complete: !!message.complete
          },
        ];

        const lastMessage = previousMessages[newMessagesIndex];
        if (
          !message.complete &&
          !lastMessage?.scrollToEndFix &&
          !message.isUser
        ) {
          newMessages.push({
            id: `${newMessagesIndex + 1}`,
            parts: [{
              type: 'text',
              content: {
                Content: {
                  Text: '',
                }
              }
            }],
            createdAt: new Date(),
            isUser: false,
            scrollToEndFix: true,
            complete: false
          });
        }

        return newMessages;
      });
    },
    []
  );

  const sendMessage = useCallback(
    (text: string) => {
      setRecevingNewMessage(true);
      const index = messages.length;

      setMessages((prev) => [
        ...prev,
        {
          id: `${index}`,
          parts: [{
            type: 'text',
            content: {
              Content: {
                Text: text,
              }
            }
          }],
          createdAt: new Date(),
          isUser: true,
          scrollToEndFix: false,
          complete: true
        },
      ]);

      // Implement socket message sending logic here
    },
    [messages]
  );

  return {
    messages,
    setMessages,
    recevingNewMessage,
    setRecevingNewMessage,
    messagesLoaded,
    handleNewMessage,
    sendMessage,
  };
};
